import mermaid from 'mermaid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm';
import seminarData from './seminarData';
import UMAPVisualization from './components/UMAPVisualization';
import SankeyChart from './components/SankeyChart';
import NetworkGraph from './components/NetworkGraph';
import HeatMap from './components/HeatMap';
import EmploymentComparison from './components/EmploymentComparison';
import IndustryTechAnalysis from './components/IndustryTechAnalysis';
import DetailedJobAnalysis from './components/DetailedJobAnalysis';

// 상단에 스타일 정의 추가
const globalStyle = `
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');
  
  .mermaid {
    font-family: 'Noto Sans KR', sans-serif !important;
    margin: 2rem auto !important;
    width: 100% !important;
    max-width: 1200px !important;
  }

  .mermaid svg {
    max-width: 100% !important;
    height: auto !important;
  }

  /* 노드 텍스트 크기 증가 */
  .mermaid .node text {
    font-size: 16px !important;  /* 14px에서 16px로 증가 */
    font-weight: 500 !important;
    line-height: 1.4 !important; /* 줄간격 추가 */
  }

  /* 서브그래프 레이블 텍스트 */
  .mermaid .cluster-label text {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  /* 서브그래프 스타일링 */
  .mermaid .cluster rect {
    fill: #f8f9fa !important;
    rx: 4 !important;
    ry: 4 !important;
  }

  /* 선 스타일링 */
  .mermaid .edgePath .path {
    stroke-width: 2px !important;
  }

  /* 노드 크기 자동 조정 */
  .mermaid .node rect,
  .mermaid .node circle,
  .mermaid .node polygon {
    rx: 5 !important;
    ry: 5 !important;
  }
`;

// mermaid 초기화 설정
mermaid.initialize({
  startOnLoad: false,
  theme: 'default',
  securityLevel: 'loose',
  flowchart: {
    htmlLabels: true,
    useMaxWidth: true,
    curve: 'basis',
    rankSpacing: 100,
    nodeSpacing: 100,
    padding: 40,
    defaultRenderer: 'dagre',
    diagramPadding: 40,
    rankDir: 'LR',
    wrap: true,          // 자동 줄바꿈 활성화
    labelMaxWidth: 200   // 라벨의 최대 너비 설정
  },
  fontFamily: 'Noto Sans KR',
  fontSize: 16           // 14에서 16으로 증가
});

const MermaidComponent = ({ content }) => {
  const elementId = `mermaid-${Math.random().toString(36).substr(2, 9)}`;
  const [error, setError] = useState(null);
  const [svgHeight, setSvgHeight] = useState('auto');

  useEffect(() => {
    let mounted = true;

    const renderDiagram = async () => {
      try {
        const container = document.getElementById(elementId);
        if (!container || !mounted) return;

        // Clear existing content
        container.innerHTML = '';
        document.querySelectorAll(`.${elementId}-svg`).forEach(el => el.remove());

        // Initialize mermaid with specific config for this render
        mermaid.initialize({
          startOnLoad: false,
          theme: 'default',
          securityLevel: 'loose',
          flowchart: {
            htmlLabels: true,
            useMaxWidth: true,
            curve: 'basis',
            rankSpacing: 100,  // 간격 늘림
            nodeSpacing: 100,  // 간격 늘림
            padding: 40,       // 패딩 늘림
            rankDir: 'LR',     // 왼쪽에서 오른쪽으로 
            diagramPadding: 40 // 다이어그램 전체 패딩
          },
          fontFamily: 'Noto Sans KR',
          fontSize: 14
        });

        const { svg } = await mermaid.render(`${elementId}-svg`, content);
        container.innerHTML = svg;

        // Adjust SVG styling
        const svgElement = container.querySelector('svg');
        if (svgElement) {
          const bbox = svgElement.getBBox();
          const width = bbox.width + 80;   // 여백 늘림
          const height = bbox.height + 80;  // 여백 늘림

          // viewBox 설정 수정
          svgElement.setAttribute('viewBox', `
            ${bbox.x - 40} 
            ${bbox.y - 40} 
            ${width} 
            ${height}
          `);
          
          // SVG 스타일 설정
          svgElement.style.width = '100%';
          svgElement.style.height = '100%';
          svgElement.style.maxWidth = '1200px';  // 최대 너비 설정
          svgElement.style.margin = '20px auto'; // 중앙 정렬
          
          // 컨테이너 높이 업데이트
          setSvgHeight(`${height}px`);
        }

        setError(null);
      } catch (err) {
        console.error('Mermaid rendering error:', err);
        setError(err.message);
      }
    };

    renderDiagram();

    return () => {
      mounted = false;
      document.querySelectorAll(`.${elementId}-svg`).forEach(el => el.remove());
    };
  }, [content, elementId]);

  return (
    <div 
      id={elementId}
      style={{ 
        margin: '2rem 0',
        padding: '1rem',
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        minHeight: '100px',
        height: svgHeight,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        position: 'relative'  // 위치 관련 스타일 추가
      }}
    >
      {error && (
        <pre style={{ color: 'red' }}>
          Error rendering diagram:
          {content}
          {error}
        </pre>
      )}
    </div>
  );
};

const Accordion = ({ title, children, isOpen, toggle }) => {
  return (
    <div style={{ marginBottom: '1rem', border: '1px solid #d1d5db', borderRadius: '0.5rem' }}>
      <h2
        onClick={toggle}
        style={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          backgroundColor: '#f1f5f9',
          padding: '1rem',
          borderRadius: '0.5rem 0.5rem 0 0',
          margin: 0,
        }}
      >
        {title}
      </h2>
      {isOpen && (
        <div
          style={{
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '0 0 0.5rem 0.5rem',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const Calendar = ({ seminars, onDateClick, selectedDate, setCurrentDate }) => {
  const [currentDate, setCurrentDateInternal] = useState(new Date(selectedDate || Date.now()));

  useEffect(() => {
    if (selectedDate) {
      setCurrentDateInternal(new Date(selectedDate));
      setCurrentDate(new Date(selectedDate));
    }
  }, [selectedDate, setCurrentDate]);

  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const seminarDates = seminars.reduce((acc, seminar) => {
    const date = new Date(seminar.date);
    if (date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
      acc[date.getDate()] = seminar.round;
    }
    return acc;
  }, {});

  const handlePrevMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    setCurrentDateInternal(newDate);
    setCurrentDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDateInternal(newDate);
    setCurrentDate(newDate);
  };

  return (
    <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <button onClick={handlePrevMonth}>&lt;</button>
        <span>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
        <button onClick={handleNextMonth}>&gt;</button>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '5px' }}>
        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
          <div key={day} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {day}
          </div>
        ))}
        {Array(firstDayOfMonth)
          .fill(null)
          .map((_, index) => (
            <div key={`empty-${index}`} />
          ))}
        {days.map((day) => (
          <div
            key={day}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: 
                selectedDate && 
                selectedDate.getDate() === day && 
                selectedDate.getMonth() === currentDate.getMonth() && 
                selectedDate.getFullYear() === currentDate.getFullYear()
                  ? '#3b82f6'  // 선택된 날짜는 파란색으로 표시
                  : seminarDates[day] 
                  ? '#bfdbfe' 
                  : 'transparent',
              color: 
                selectedDate && 
                selectedDate.getDate() === day && 
                selectedDate.getMonth() === currentDate.getMonth() && 
                selectedDate.getFullYear() === currentDate.getFullYear()
                  ? 'white'  // 선택된 날짜의 텍스트는 흰색으로
                  : 'inherit',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: 'auto',
            }}
            onClick={() => onDateClick(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

const UMAPWrapper = () => <UMAPVisualization />;
const SankeyWrapper = () => <SankeyChart />;
const NetworkWrapper = () => <NetworkGraph />;
const HeatmapWrapper = () => <HeatMap />;
const EmploymentComparisonWrapper = () => <EmploymentComparison />;
const IndustryTechAnalysisWrapper = () => <IndustryTechAnalysis />;
const DetailedJobAnalysisWrapper = () => <DetailedJobAnalysis />;

const components = {
  code({ node, inline, className = '', children, ...props }) {
    const language = (className.match(/language-(\w+)/) || [])[1];
    if (language === 'mermaid') {
      const content = String(children)
        .replace(/\n$/, '')
        .replace(/%%[^%]*%%/g, '')
        .replace(/^\s+|\s+$/g, '');
      
      if (content) {
        console.log('Mermaid content:', content); // 디버깅을 위한 로그 추가
        return <MermaidComponent content={content} />;
      }
      return null;
    } else if (language === 'umap') {
      return <UMAPWrapper />;
    } else if (language === 'sankey') {
      return <SankeyWrapper />;
    } else if (language === 'network') {
      return <NetworkWrapper />;
    } else if (language === 'heatmap') {
      return <HeatmapWrapper />;
    } else if (language === 'employmentcomparison') {
      return <EmploymentComparisonWrapper />;
    } else if (language === 'indtechAnalysis') {
      return <IndustryTechAnalysisWrapper />;
    } else if (language === 'detailtechAnalysis') {
      return <DetailedJobAnalysisWrapper />;
    } else {
      return <code className={className} {...props}>{children}</code>;
    }
  },
  // 기존 스타일링 컴포넌트
  p: ({ node, ...props }) => <p style={{ marginTop: '1em', marginBottom: '1em' }} {...props} />,
  h1: ({ node, ...props }) => <h1 style={{ marginTop: '1.5em', marginBottom: '0.5em' }} {...props} />,
  h2: ({ node, ...props }) => <h2 style={{ marginTop: '1.5em', marginBottom: '0.5em' }} {...props} />,
  h3: ({ node, ...props }) => <h3 style={{ marginTop: '1.2em', marginBottom: '0.5em' }} {...props} />,
  ul: ({ node, ...props }) => <ul style={{ marginTop: '1em', marginBottom: '1em' }} {...props} />,
  ol: ({ node, ...props }) => <ol style={{ marginTop: '1em', marginBottom: '1em' }} {...props} />,
  li: ({ node, ...props }) => <li style={{ marginTop: '0.2em', marginBottom: '0.2em' }} {...props} />,
  table: ({ node, ...props }) => <table style={{ marginTop: '1em', marginBottom: '1em', borderCollapse: 'collapse' }} {...props} />,
  th: ({ node, ...props }) => <th style={{ border: '1px solid #ddd', padding: '8px' }} {...props} />,
  td: ({ node, ...props }) => <td style={{ border: '1px solid #ddd', padding: '8px' }} {...props} />,
};


const Dashboard = () => {
  const [selectedSeminar, setSelectedSeminar] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [documentContent, setDocumentContent] = useState('');
  const [filteredSeminars, setFilteredSeminars] = useState(seminarData);
  const [isCalendarOpen, setCalendarOpen] = useState(true);
  const [isSeminarListOpen, setSeminarListOpen] = useState(true);
  const [isSeminarDetailsOpen, setSeminarDetailsOpen] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const fetchDocument = async (path) => {
    try {
      const response = await axios.get(path);
      setDocumentContent(response.data);
      setSelectedDocument(path);
      setShowDocument(true);
    } catch (error) {
      console.error('Error fetching document:', error);
      setDocumentContent('문서를 불러오는 데 실패했습니다.');
      setSelectedDocument(null);
      setShowDocument(true);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const selected = seminarData.find(
      (seminar) => new Date(seminar.date).toDateString() === date.toDateString()
    );
    setSelectedSeminar(selected || null);
    setFilteredSeminars(selected ? [selected] : []);
    setShowDocument(false);
  };

  const handleShowAllSeminars = () => {
    setSelectedDate(null);
    setSelectedSeminar(null);
    setFilteredSeminars(seminarData);
    setShowDocument(false);
  };

  const handleSeminarClick = (seminar) => {
    const seminarDate = new Date(seminar.date);
    setSelectedSeminar(seminar);
    setShowDocument(false);
    setSelectedDate(seminarDate);
    setCurrentDate(seminarDate);
    setFilteredSeminars([seminar]);
  };

  const handleDownload = (content, filename) => {
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/markdown'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // 나머지 JSX 부분은 그대로 유지
  return (
    <div style={{ padding: '1rem', backgroundColor: '#f3f4f6', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* 기존 JSX 코드 유지 */}
      <div
        style={{
          backgroundColor: '#e5e7eb',
          borderRadius: '0.5rem',
          padding: '1.5rem',
          marginBottom: '2rem',
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1
          style={{
            fontSize: '2rem',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#2563eb',
            margin: 0,
            paddingLeft: '1rem',
          }}
        >
          K2S3 인공지능 세미나 대시보드
        </h1>
        <a 
          href="https://k2s3-chat.duckdns.org" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#2563eb',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '0.25rem',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#1d4ed8'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#2563eb'}
        >
          K2S3 Chat 바로가기
        </a>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem', flex: 1, overflow: 'hidden'}}>
        {isSidebarOpen && (
          <div style={{ width: '20%', backgroundColor: '#e5e7eb', padding: '1rem', borderRadius: '0.5rem', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', transition: 'width 0.3s ease-in-out', overflowY: 'auto', }}>
            {/* 기존 사이드바 내용 유지 */}
            <Accordion
              title="세미나 일정"
              isOpen={isCalendarOpen}
              toggle={() => setCalendarOpen(!isCalendarOpen)}
            >
              <div style={{ textAlign: 'center' }}>
                <Calendar 
                  seminars={seminarData} 
                  onDateClick={handleDateClick} 
                  selectedDate={selectedDate}
                  setCurrentDate={setCurrentDate}
                />
              </div>
            </Accordion>
  
            <Accordion
              title="세미나 목록"
              isOpen={isSeminarListOpen}
              toggle={() => setSeminarListOpen(!isSeminarListOpen)}
            >
              <button
                onClick={handleShowAllSeminars}
                style={{
                  marginBottom: '1rem',
                  padding: '0.5rem 1rem',
                  backgroundColor: '#2563eb',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.25rem',
                  cursor: 'pointer',
                }}
              >
                모든 세미나 보기
              </button>
              <ul style={{ listStyleType: 'none', padding: 0, maxHeight: '24rem', overflowY: 'auto' }}>
                {filteredSeminars.map((seminar) => (
                  <li
                    key={seminar.id}
                    onClick={() => handleSeminarClick(seminar)}
                    style={{
                      cursor: 'pointer',
                      padding: '0.75rem',
                      borderRadius: '0.25rem',
                      marginBottom: '0.5rem',
                      transition: 'background-color 0.15s ease-in-out',
                      backgroundColor: selectedSeminar?.id === seminar.id ? '#bfdbfe' : 'transparent',
                    }}
                  >
                    <div style={{ color: '#2563eb', fontWeight: 'bold' }}>• 제{seminar.round}회 - {seminar.date}</div>
                    {seminar.topics.map((topic, index) => (
                      <div key={index} style={{ marginLeft: '1rem', marginTop: '0.25rem' }}>
                        • 주제 {index + 1} : {topic.title}
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
            </Accordion>
            
            {selectedSeminar && (
              <Accordion
                title="세미나 상세정보"
                isOpen={isSeminarDetailsOpen}
                toggle={() => setSeminarDetailsOpen(!isSeminarDetailsOpen)}
              >
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {selectedSeminar.topics.map((topic, index) => (
                    <li key={index} style={{ marginBottom: '1rem' }}>
                      <div style={{ color: '#2563eb', fontWeight: 'bold' }}>• 주제 {index + 1}</div>
                      <div style={{ marginLeft: '1rem' }}>{topic.title}</div>
                      <div style={{ marginLeft: '1rem', fontSize: '0.875rem', color: '#4b5563' }}>
                        발표자 - {topic.presenter}
                      </div>
                      <div style={{ marginLeft: '1rem', marginTop: '0.5rem' }}>
                        <button
                          onClick={() => fetchDocument(topic.documentPath)}
                          style={{
                            padding: '0.5rem 1rem',
                            backgroundColor: '#2563eb',
                            color: 'white',
                            border: 'none',
                            borderRadius: '0.25rem',
                            cursor: 'pointer',
                          }}
                        >
                          발표자료
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </Accordion>
            )}
          </div>
        )}

        <div style={{ 
          flex: 1, 
          backgroundColor: '#e5e7eb', 
          padding: '1rem', 
          borderRadius: '0.5rem', 
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)', 
          transition: 'flex 0.3s ease-in-out', 
          overflowY: 'auto', 
          position: 'relative',
        }}>
          {showDocument && (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                padding: '1rem',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                marginBottom: '1rem',
              }}>
                <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold', margin: 0 }}>
                  제{selectedSeminar?.round}회 - 주제 {selectedSeminar?.topics.findIndex(topic => topic.documentPath === selectedDocument) + 1}
                </h2>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#f3f4f6',
                  borderRadius: '0.25rem',
                  overflow: 'hidden',
                }}>
                  <button
                    onClick={() => setSidebarOpen(!isSidebarOpen)}
                    style={{
                      padding: '0.25rem 0.5rem',
                      backgroundColor: 'transparent',
                      color: '#4b5563',
                      border: 'none',
                      fontSize: '1.25rem',
                      cursor: 'pointer',
                      borderRight: '1px solid #d1d5db',
                    }}
                    title={isSidebarOpen ? "사이드바 접기" : "사이드바 펼치기"}
                  >
                    {isSidebarOpen ? '«' : '»'}
                  </button>
                  {/* 다운로드 버튼 추가 */}
                  <button
                    onClick={() => handleDownload(documentContent, selectedDocument.split('/').pop())}
                    style={{
                      padding: '0.25rem 0.5rem',
                      backgroundColor: 'transparent',
                      color: '#4b5563',
                      border: 'none',
                      fontSize: '1.25rem',
                      cursor: 'pointer',
                      borderRight: '1px solid #d1d5db',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    title="문서 다운로드"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="12" y1="18" x2="12" y2="12"></line>
                      <line x1="9" y1="15" x2="15" y2="15"></line>
                    </svg>
                  </button>
                  <button
                    onClick={() => setShowDocument(false)}
                    style={{
                      padding: '0.25rem 0.5rem',
                      backgroundColor: 'transparent',
                      color: '#4b5563',
                      border: 'none',
                      fontSize: '1.25rem',
                      cursor: 'pointer',
                    }}
                    title="문서 닫기"
                  >
                    ×
                  </button>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <ReactMarkdown 
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={components}
                >
                  {documentContent}
                </ReactMarkdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;