// src/components/NetworkGraph.jsx

import React, { useEffect, useState, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import Modal from 'react-modal';

// 모달 스타일 설정 (필요에 따라 커스터마이징 가능)
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '400px',
    width: '80%',
  },
};

const NetworkGraph = () => {
  const [data, setData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const fgRef = useRef();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/network_data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.error('Error fetching network data:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading Network Graph...</div>;
  }

  // 그룹별 색상 지정
  const groupColors = {
    'Skill': 'blue',
    'Advanced Field': 'orange',
    'Industry': 'green',
    'Job Role': 'red',
  };

  // 노드 클릭 이벤트 핸들러
  const handleNodeClick = (node) => {
    setSelectedNode(node);
  };

  // 모달 창 닫기 핸들러
  const closeModal = () => {
    setSelectedNode(null);
  };

  // 화면 크기에 따라 그래프 크기 조절
  const width = window.innerWidth * 0.8;
  const height = window.innerHeight * 0.6;

  return (
    <div>
      <ForceGraph2D
        ref={fgRef}
        graphData={data}
        nodeAutoColorBy="group"
        nodeCanvasObject={(node, ctx, globalScale) => {
          const label = node.id;
          const fontSize = 12 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.fillStyle = groupColors[node.group] || 'gray';
          ctx.beginPath();
          ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
          ctx.fill();
          ctx.fillStyle = 'black';
          ctx.fillText(label, node.x + 8, node.y + 4);
        }}
        nodeLabel={(node) => `${node.id} (${node.group})`}
        onNodeClick={handleNodeClick}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        linkDirectionalArrowLength={5}
        linkDirectionalArrowRelPos={1}
        linkWidth={1}
        linkDirectionalParticles={2}
        linkDirectionalParticleSpeed={0.005}
        width={width}
        height={height}
      />

      {selectedNode && (
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          contentLabel="노드 상세 정보"
          style={customStyles}
        >
          <h2>{selectedNode.id}</h2>
          <p><strong>그룹:</strong> {selectedNode.group}</p>
          {/* 노드의 추가 정보를 표시 */}
          {selectedNode.description && (
            <p><strong>설명:</strong> {selectedNode.description}</p>
          )}
          {selectedNode.url && (
            <p>
              <strong>관련 링크:</strong>{' '}
              <a href={selectedNode.url} target="_blank" rel="noopener noreferrer">
                자세히 보기
              </a>
            </p>
          )}
          <button onClick={closeModal}>닫기</button>
        </Modal>
      )}
    </div>
  );
};

export default NetworkGraph;
