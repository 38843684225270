import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const EmploymentComparison = () => {
  const [data, setData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('계');
  const [chartType, setChartType] = useState('absolute');
  const [viewMode, setViewMode] = useState('monthly'); // 'monthly' or 'total'

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/employment-data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.error('Error fetching employment data:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const calculateRatio = (value, totalValue) => {
    return (value / totalValue) * 100;
  };

  const transformDataForPlot = (category) => {
    if (viewMode === 'monthly') {
      const laborData = data.laborSurvey.data.find(d => d.category === category);
      const jobkoreaData = data.jobkorea.data.find(d => d.category === category);
      const totalLabor = data.laborSurvey.data.find(d => d.category === '계');
      const totalJobkorea = data.jobkorea.data.find(d => d.category === '계');

      if (chartType === 'absolute') {
        return [
          {
            name: '사업체노동력조사',
            type: 'bar',
            x: data.laborSurvey.months,
            y: laborData.values,
            marker: { color: '#1f77b4' }
          },
          {
            name: 'JOBKOREA',
            type: 'bar',
            x: data.jobkorea.months,
            y: jobkoreaData.values,
            marker: { color: '#ff7f0e' }
          }
        ];
      } else {
        return [
          {
            name: '사업체노동력조사',
            type: 'bar',
            x: data.laborSurvey.months,
            y: laborData.values.map((value, index) => 
              calculateRatio(value, totalLabor.values[index]).toFixed(1)
            ),
            marker: { color: '#1f77b4' }
          },
          {
            name: 'JOBKOREA',
            type: 'bar',
            x: data.jobkorea.months,
            y: jobkoreaData.values.map((value, index) => 
              calculateRatio(value, totalJobkorea.values[index]).toFixed(1)
            ),
            marker: { color: '#ff7f0e' }
          }
        ];
      }
    } else {
      // 산업별 전체 합계 데이터 준비
      const categories = data.laborSurvey.data
        .filter(d => d.category !== '계')
        .map(d => d.category);
      
      const laborSums = categories.map(cat => {
        const values = data.laborSurvey.data.find(d => d.category === cat).values;
        return values.reduce((a, b) => a + b, 0);
      });
      
      const jobkoreaSums = categories.map(cat => {
        const values = data.jobkorea.data.find(d => d.category === cat).values;
        return values.reduce((a, b) => a + b, 0);
      });

      const totalLabor = laborSums.reduce((a, b) => a + b, 0);
      const totalJobkorea = jobkoreaSums.reduce((a, b) => a + b, 0);

      if (chartType === 'absolute') {
        return [
          {
            name: '사업체노동력조사',
            type: 'bar',
            x: categories,
            y: laborSums,
            marker: { color: '#1f77b4' }
          },
          {
            name: 'JOBKOREA',
            type: 'bar',
            x: categories,
            y: jobkoreaSums,
            marker: { color: '#ff7f0e' }
          }
        ];
      } else {
        return [
          {
            name: '사업체노동력조사',
            type: 'bar',
            x: categories,
            y: laborSums.map(value => calculateRatio(value, totalLabor).toFixed(1)),
            marker: { color: '#1f77b4' }
          },
          {
            name: 'JOBKOREA',
            type: 'bar',
            x: categories,
            y: jobkoreaSums.map(value => calculateRatio(value, totalJobkorea).toFixed(1)),
            marker: { color: '#ff7f0e' }
          }
        ];
      }
    }
  };

  const calculateStats = (category) => {
    const laborData = data.laborSurvey.data.find(d => d.category === category);
    const jobkoreaData = data.jobkorea.data.find(d => d.category === category);
    const totalLabor = data.laborSurvey.data.find(d => d.category === '계');
    const totalJobkorea = data.jobkorea.data.find(d => d.category === '계');

    const laborSum = laborData.values.reduce((a, b) => a + b, 0);
    const jobkoreaSum = jobkoreaData.values.reduce((a, b) => a + b, 0);
    const laborAvg = Math.round(laborSum / laborData.values.length);
    const jobkoreaAvg = Math.round(jobkoreaSum / jobkoreaData.values.length);
    
    const laborGrowth = Math.round(((laborData.values[laborData.values.length - 1] - laborData.values[0]) / laborData.values[0]) * 100);
    const jobkoreaGrowth = Math.round(((jobkoreaData.values[jobkoreaData.values.length - 1] - jobkoreaData.values[0]) / jobkoreaData.values[0]) * 100);

    const totalLaborSum = totalLabor.values.reduce((a, b) => a + b, 0);
    const totalJobkoreaSum = totalJobkorea.values.reduce((a, b) => a + b, 0);

    const laborRatioAvg = ((laborSum / totalLaborSum) * 100).toFixed(1);
    const jobkoreaRatioAvg = ((jobkoreaSum / totalJobkoreaSum) * 100).toFixed(1);

    return { 
      laborAvg, 
      jobkoreaAvg, 
      laborGrowth, 
      jobkoreaGrowth,
      laborRatioAvg,
      jobkoreaRatioAvg,
      laborSum,
      jobkoreaSum
    };
  };

  const layout = {
    title: viewMode === 'monthly' 
      ? `${selectedCategory} ${chartType === 'absolute' ? '채용공고 비교' : '채용공고 비율 비교 (%)'}` 
      : `산업별 ${chartType === 'absolute' ? '총 채용건수' : '채용 비율'} 비교`,
    barmode: 'group',
    xaxis: {
      title: viewMode === 'monthly' ? '월' : '산업분류',
      tickangle: viewMode === 'monthly' ? -45 : -45
    },
    yaxis: {
      title: chartType === 'absolute' ? '채용건수' : '비율 (%)'
    },
    width: 1000,
    height: viewMode === 'monthly' ? 500 : 700,
    margin: { t: 50, b: 150 }
  };

  const stats = calculateStats(selectedCategory);

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <div style={{ 
        marginBottom: '20px',
        display: 'grid',
        gridTemplateColumns: viewMode === 'monthly' ? '2fr 1fr 1fr' : '1fr 1fr',
        gap: '20px'
      }}>
        {viewMode === 'monthly' && (
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            {data.laborSurvey.data.map(item => (
              <option key={item.category} value={item.category}>
                {item.category}
              </option>
            ))}
          </select>
        )}
        <select
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <option value="absolute">채용건수</option>
          <option value="ratio">비율</option>
        </select>
        <select
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <option value="monthly">월별 비교</option>
          <option value="total">산업별 총계</option>
        </select>
      </div>

      <Plot
        data={transformDataForPlot(selectedCategory)}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />

      {viewMode === 'monthly' && (
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gap: '20px',
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px'
        }}>
          <div>
            <h3 style={{ marginBottom: '10px', fontSize: '18px' }}>사업체노동력조사</h3>
            <p>총 채용건수: {stats.laborSum.toLocaleString()}</p>
            <p>평균 채용건수: {stats.laborAvg.toLocaleString()}</p>
            <p>성장률: {stats.laborGrowth}%</p>
            <p>전체 대비 비율: {stats.laborRatioAvg}%</p>
          </div>
          <div>
            <h3 style={{ marginBottom: '10px', fontSize: '18px' }}>JOBKOREA</h3>
            <p>총 채용건수: {stats.jobkoreaSum.toLocaleString()}</p>
            <p>평균 채용건수: {stats.jobkoreaAvg.toLocaleString()}</p>
            <p>성장률: {stats.jobkoreaGrowth}%</p>
            <p>전체 대비 비율: {stats.jobkoreaRatioAvg}%</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmploymentComparison;