import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const IndustryTechAnalysis = () => {
  const [data, setData] = useState(null);
  const [viewMode, setViewMode] = useState('heatmap');
  const [analysisType, setAnalysisType] = useState('industry');
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [selectedTechnology, setSelectedTechnology] = useState(1);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/industry-tech-data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const calculatePercentage = (value, total) => {
    return ((value / total) * 100).toFixed(1);
  };

  const calculateLQ = () => {
    const totalNational = data.regions.data[0][0];
    const techNationalShare = data.regions.data[0].map(v => v / totalNational);
    
    return data.regions.data.slice(1).map((region) => {
      const totalRegional = region[0];
      return region.slice(1).map((value, j) => {
        const regionalShare = value / totalRegional;
        const nationalShare = techNationalShare[j + 1];
        return regionalShare / nationalShare;
      });
    });
  };

  const getHeatmapData = () => {
    if (analysisType === 'industry') {
      return [{
        z: data.industries.data.slice(1).map(row => row.slice(1)),
        x: data.technologies.slice(1),
        y: data.industries.categories.slice(1),
        type: 'heatmap',
        colorscale: 'YlOrRd',
        showscale: true,
        hoverongaps: false,
        colorbar: { title: '채용건수' }
      }];
    } else {
      const lqData = calculateLQ();
      return [{
        z: lqData,
        x: data.technologies.slice(1),
        y: data.regions.categories.slice(1),
        type: 'heatmap',
        colorscale: [
          [0, '#ffffff'],
          [0.5, '#ffffcc'],
          [0.8, '#fed976'],
          [1, '#bd0026']
        ],
        showscale: true,
        hoverongaps: false,
        colorbar: { 
          title: 'LQ 지수',
          ticktext: ['비특화(0)', '평균(1)', '특화(3+)'],
          tickvals: [0, 1, 3]
        },
        zmin: 0,
        zmax: 3
      }];
    }
  };

  const getCategoryAnalysis = () => {
    const analysisData = data[analysisType === 'industry' ? 'industries' : 'regions'];
    const categoryData = analysisData.data[selectedCategory];
    const total = categoryData[0];
    
    return [{
      x: data.technologies.slice(1),
      y: categoryData.slice(1).map(value => calculatePercentage(value, total)),
      type: 'bar',
      marker: { color: '#1f77b4' },
      name: '비율 (%)'
    }];
  };

  const getTechnologyAnalysis = () => {
    const analysisData = data[analysisType === 'industry' ? 'industries' : 'regions'];
    const techValues = analysisData.data.map(row => row[selectedTechnology]);
    const techTotal = techValues[0];
    
    return [{
      x: analysisData.categories.slice(1),
      y: techValues.slice(1).map(value => calculatePercentage(value, techTotal)),
      type: 'bar',
      marker: { color: '#2ca02c' },
      name: '비율 (%)'
    }];
  };

  const getLayout = () => {
    const baseLayout = {
      width: 1100,
      height: 700,
      margin: { l: 150, r: 50, t: 50, b: 150 }
    };

    const analysisData = data[analysisType === 'industry' ? 'industries' : 'regions'];

    switch (viewMode) {
      case 'heatmap':
        return {
          ...baseLayout,
          title: `${analysisType === 'industry' ? '산업' : '지역'}-첨단기술 ${analysisType === 'industry' ? '채용 현황' : 'LQ 지수'} 히트맵`,
          xaxis: {
            title: '첨단기술 분야',
            tickangle: -45
          },
          yaxis: {
            title: analysisType === 'industry' ? '산업분류' : '지역'
          }
        };
      case 'category':
        return {
          ...baseLayout,
          title: `${analysisData.categories[selectedCategory]} ${analysisType === 'industry' ? '산업' : '지역'}의 첨단기술 분야별 채용 비율`,
          xaxis: {
            title: '첨단기술 분야',
            tickangle: -45
          },
          yaxis: {
            title: '채용 비율 (%)',
            range: [0, 100]
          }
        };
      case 'technology':
        return {
          ...baseLayout,
          title: `${data.technologies[selectedTechnology]} 분야의 ${analysisType === 'industry' ? '산업' : '지역'}별 채용 비율`,
          xaxis: {
            title: analysisType === 'industry' ? '산업분류' : '지역',
            tickangle: -45
          },
          yaxis: {
            title: '채용 비율 (%)',
            range: [0, 100]
          }
        };
    }
  };

  const getPlotData = () => {
    switch (viewMode) {
      case 'heatmap':
        return getHeatmapData();
      case 'category':
        return getCategoryAnalysis();
      case 'technology':
        return getTechnologyAnalysis();
    }
  };

  const renderRegionalSummary = () => {
    if (viewMode !== 'heatmap' || analysisType !== 'region') return null;

    const regions = {
      '수도권': ['서울', '경기', '인천'],
      '충청권': ['대전', '세종', '충북', '충남'],
      '영남권': ['부산', '대구', '울산', '경북', '경남'],
      '호남권': ['광주', '전북', '전남'],
      '기타': ['강원', '제주']
    };

    const lqData = calculateLQ();
    const regionalSummary = {};

    Object.entries(regions).forEach(([region, cities]) => {
      const regionIndices = cities.map(city => 
        data.regions.categories.slice(1).findIndex(r => r === city)
      ).filter(idx => idx !== -1);

      const regionLQs = data.technologies.slice(1).map((tech, techIdx) => {
        const avgLQ = regionIndices.reduce((sum, cityIdx) => 
          sum + lqData[cityIdx][techIdx], 0
        ) / regionIndices.length;

        return {
          technology: tech,
          lq: avgLQ
        };
      });

      regionalSummary[region] = regionLQs
        .sort((a, b) => b.lq - a.lq)
        .slice(0, 3);
    });

    return (
      <div style={{ 
        marginTop: '20px', 
        padding: '20px', 
        backgroundColor: '#f5f5f5', 
        borderRadius: '8px'
      }}>
        <h3>권역별 특화 기술 (LQ 지수 기준)</h3>
        {Object.entries(regionalSummary).map(([region, techs]) => (
          <div key={region} style={{ marginTop: '15px' }}>
            <h4 style={{ marginBottom: '8px' }}>{region}</h4>
            {techs.map((tech, idx) => (
              <div key={idx} style={{ 
                display: 'inline-block', 
                margin: '0 10px 5px 0',
                padding: '4px 8px',
                backgroundColor: tech.lq >= 3 ? '#bd0026' : 
                               tech.lq >= 1.2 ? '#fed976' : '#ffffcc',
                color: tech.lq >= 3 ? 'white' : 'black',
                borderRadius: '4px',
                fontSize: '0.9em'
              }}>
                {tech.technology} (LQ: {tech.lq.toFixed(2)})
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderStats = () => {
    if (viewMode === 'heatmap') return null;

    const analysisData = data[analysisType === 'industry' ? 'industries' : 'regions'];

    if (viewMode === 'category') {
      const categoryData = analysisData.data[selectedCategory];
      const total = categoryData[0];
      const topTechs = data.technologies.slice(1)
        .map((tech, idx) => ({
          name: tech,
          value: categoryData[idx + 1],
          percentage: calculatePercentage(categoryData[idx + 1], total)
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5);

      return (
        <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
          <h3>상위 5개 첨단기술 분야</h3>
          <div style={{ marginTop: '10px' }}>
            {topTechs.map((tech, idx) => (
              <p key={idx}>
                {idx + 1}. {tech.name}: {tech.value}명 ({tech.percentage}%)
              </p>
            ))}
          </div>
        </div>
      );
    }

    if (viewMode === 'technology') {
      const techValues = analysisData.data.map(row => row[selectedTechnology]);
      const techTotal = techValues[0];
      const topCategories = analysisData.categories.slice(1)
        .map((category, idx) => ({
          name: category,
          value: analysisData.data[idx + 1][selectedTechnology],
          percentage: calculatePercentage(analysisData.data[idx + 1][selectedTechnology], techTotal)
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5);

      return (
        <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
          <h3>상위 5개 {analysisType === 'industry' ? '산업' : '지역'}</h3>
          <div style={{ marginTop: '10px' }}>
            {topCategories.map((category, idx) => (
              <p key={idx}>
                {idx + 1}. {category.name}: {category.value}명 ({category.percentage}%)
              </p>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <div style={{ 
        marginBottom: '20px',
        display: 'grid',
        gridTemplateColumns: viewMode === 'heatmap' ? '1fr 1fr' : '1fr 1fr 2fr',
        gap: '20px'
      }}>
        <select
          value={analysisType}
          onChange={(e) => setAnalysisType(e.target.value)}
          style={{
            padding: '8px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <option value="industry">산업별 분석</option>
          <option value="region">지역별 분석</option>
        </select>

        <select
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
          style={{
            padding: '8px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <option value="heatmap">히트맵</option>
          <option value="category">{analysisType === 'industry' ? '산업별' : '지역별'} 분석</option>
          <option value="technology">기술별 분석</option>
        </select>

        {viewMode === 'category' && (
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(Number(e.target.value))}
            style={{
              padding: '8px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            {data[analysisType === 'industry' ? 'industries' : 'regions'].categories.slice(1).map((category, idx) => (
              <option key={idx} value={idx + 1}>
                {category}
              </option>
            ))}
          </select>
        )}

        {viewMode === 'technology' && (
          <select
            value={selectedTechnology}
            onChange={(e) => setSelectedTechnology(Number(e.target.value))}
            style={{
              padding: '8px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
          >
            {data.technologies.slice(1).map((tech, idx) => (
              <option key={idx} value={idx + 1}>
                {tech}
              </option>
            ))}
          </select>
        )}
      </div>

      <Plot
        data={getPlotData()}
        layout={getLayout()}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />

      {renderStats()}
      {renderRegionalSummary()}
    </div>
  );
};

export default IndustryTechAnalysis;