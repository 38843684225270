import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const DetailedJobAnalysis = () => {
  const [data, setData] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  
  const COLORS = {
    "인공지능(AI)": "#9CB4CC",      // 파란색
    "차세대반도체": "#B784B7",      // 보라색
    "클라우드": "#E8A9A9",          // 분홍색
    "바이오헬스": "#E8A9A9",        // 분홍색
    "빅데이터": "#9CB4CC",          // 파란색
    "이차전지": "#B5CB99"           // 연두색
  };

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/detailed-job-data.json`)
      .then(response => response.json())
      .then(setData)
      .catch(error => console.error('Error:', error));
  }, []);

  if (!data) return <div>Loading...</div>;

  const renderMainChart = () => {
    const width = 800;
    const height = 800;
    const centerX = width / 2;
    const centerY = height / 2;
    const innerRadius = 100;  // 가운데 원 크기 축소
    const outerRadius = 300;
    
    const elements = [];
    let currentAngle = 0;
    
    data.fields.forEach((field, fieldIndex) => {
      const fieldAngle = (360 / data.fields.length);
      const startAngle = currentAngle;
      const sortedJobs = [...field.jobs].sort((a, b) => b.value - a.value);
      
      sortedJobs.forEach((job, jobIndex) => {
        const normalizedValue = job.value / field.total;
        const barWidth = 8;
        const barSpacing = 2;
        const barAngle = (fieldAngle * 0.8) / sortedJobs.length;
        const currentBarAngle = startAngle + (jobIndex * (barAngle + barSpacing));
        
        const radius = innerRadius + (normalizedValue * (outerRadius - innerRadius));
        const startRad = ((currentBarAngle - barWidth/2) * Math.PI) / 180;
        const endRad = ((currentBarAngle + barWidth/2) * Math.PI) / 180;
        
        const innerX1 = centerX + innerRadius * Math.cos(startRad);
        const innerY1 = centerY + innerRadius * Math.sin(startRad);
        const innerX2 = centerX + innerRadius * Math.cos(endRad);
        const innerY2 = centerY + innerRadius * Math.sin(endRad);
        
        const outerX1 = centerX + radius * Math.cos(startRad);
        const outerY1 = centerY + radius * Math.sin(startRad);
        const outerX2 = centerX + radius * Math.cos(endRad);
        const outerY2 = centerY + radius * Math.sin(endRad);
        
        const d = `
          M ${innerX1} ${innerY1}
          A ${innerRadius} ${innerRadius} 0 0 1 ${innerX2} ${innerY2}
          L ${outerX2} ${outerY2}
          A ${radius} ${radius} 0 0 0 ${outerX1} ${outerY1}
          Z
        `;
        
        elements.push(
          <path
            key={`bar-${fieldIndex}-${jobIndex}`}
            d={d}
            fill={COLORS[field.name]}
            opacity={0.8}
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedField(field)}
          />
        );
        
        const labelRadius = radius + 20;
        const labelAngle = currentBarAngle * Math.PI / 180;
        const labelX = centerX + labelRadius * Math.cos(labelAngle);
        const labelY = centerY + labelRadius * Math.sin(labelAngle);
        const rotation = currentBarAngle + (currentBarAngle > 90 && currentBarAngle < 270 ? 180 : 0);
        
        elements.push(
          <text
            key={`label-${fieldIndex}-${jobIndex}`}
            x={labelX}
            y={labelY}
            fontSize="10"
            textAnchor="middle"
            transform={`rotate(${rotation},${labelX},${labelY})`}
            fill="#666"
          >
            {job.name}
          </text>
        );
      });
      
      const fieldLabelAngle = (startAngle + fieldAngle/2) * Math.PI / 180;
      const fieldLabelRadius = outerRadius + 50;
      const fieldLabelX = centerX + fieldLabelRadius * Math.cos(fieldLabelAngle);
      const fieldLabelY = centerY + fieldLabelRadius * Math.sin(fieldLabelAngle);
      const fieldRotation = (startAngle + fieldAngle/2) + 
        ((startAngle + fieldAngle/2) > 90 && (startAngle + fieldAngle/2) < 270 ? 180 : 0);
      
      elements.push(
        <text
          key={`field-${fieldIndex}`}
          x={fieldLabelX}
          y={fieldLabelY}
          fontSize="14"
          fontWeight="bold"
          textAnchor="middle"
          transform={`rotate(${fieldRotation},${fieldLabelX},${fieldLabelY})`}
          fill={COLORS[field.name]}
        >
          {field.name}
        </text>
      );
      
      currentAngle += fieldAngle;
    });

    return (
      <svg width={width} height={height}>
        <g transform={`translate(0,0)`}>
          {elements}
        </g>
      </svg>
    );
  };

  const renderDetailChart = () => {
    if (!selectedField) return null;

    const chartData = selectedField.jobs.map(job => ({
      name: job.name,
      value: job.value
    }));

    return (
      <div className="mt-8 p-6 bg-gray-50 rounded-lg">
        <h3 className="text-xl font-bold mb-4">{selectedField.name} 상세 분석</h3>
        <div className="flex">
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  fill={COLORS[selectedField.name]}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                >
                  {chartData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`}
                      fill={COLORS[selectedField.name]}
                      opacity={0.7 + (0.3 * (index / chartData.length))}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="w-1/2 pl-8">
            <div className="space-y-4">
              <div>
                <h4 className="font-bold">전체 채용공고 수</h4>
                <p className="text-2xl">{selectedField.total.toLocaleString()}건</p>
              </div>
              <div>
                <h4 className="font-bold">주요 직무 TOP 3</h4>
                <ul className="list-disc list-inside">
                  {[...selectedField.jobs]
                    .sort((a, b) => b.value - a.value)
                    .slice(0, 3)
                    .map((job, index) => (
                      <li key={index}>
                        {job.name}: {((job.value / selectedField.total) * 100).toFixed(1)}%
                        ({job.value.toLocaleString()}건)
                      </li>
                    ))}
                </ul>
              </div>
              <div>
                <h4 className="font-bold">직무 다양성</h4>
                <p>{selectedField.jobs.length}개 직무</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-6">
      <h2 className="text-2xl font-bold text-center mb-8">
        첨단 기술 분야별 주요 직무 분포
      </h2>
      {renderMainChart()}
      {renderDetailChart()}
    </div>
  );
};

export default DetailedJobAnalysis;