const seminarData = [
  {
    id: 1,
    round: 1,
    date: '2024-01-17',
    topics: [
      {
        title: '"Llama 2: Open Foundation and Fine-Tuned Chat Models" 논문 리뷰',
        presenter: '김도희',
        documentPath: '/documents/sm-1-1.md'
      }
    ]
  },
  {
    id: 2,
    round: 2,
    date: '2024-01-24',
    topics: [
      {
        title: '"Attention is All you need" 논문 리뷰',
        presenter: '김도희',
        documentPath: '/documents/sm-2-1.md'
      },
      {
        title: '"LLM 현황" 자료 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-2-2.md'
      }
    ]
  },
  {
    id: 3,
    round: 3,
    date: '2024-01-31',
    topics: [
      {
        title: '"Using Sequences of Life-events to Predict Human Lives" 논문 리뷰',
        presenter: '김도희',
        documentPath: '/documents/sm-3-1.md'
      }
    ]
  },
  {
    id: 4,
    round: 4,
    date: '2024-02-06',
    topics: [
      {
        title: '"Retrieval Augmented Generation 소개와 실습" 작업 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-4-1.md'
      }
    ]
  },
  {
    id: 5,
    round: 5,
    date: '2024-02-14',
    topics: [
      {
        title: '"K2S3 인공지능모델 개발 현황" 자료 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-5-1.md'
      },
      {
        title: '"RAG 예제2. llama_index vs. langchain" 작업 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-5-2.md'
      }
    ]
  },
  {
    id: 6,
    round: 6,
    date: '2024-02-21',
    topics: [
      {
        title: 'open 모델 평가 및 훈련 상황',
        presenter: '송창길',
        documentPath: '/documents/sm-6-1.md'
      },
      {
        title: '프롬프트 디자인 사례 정리',
        presenter: '김도희',
        documentPath: '/documents/sm-6-2.md'
      }
    ]
  },
  {
    id: 7,
    round: 7,
    date: '2024-02-28',
    topics: [
      {
        title: 'open 모델 평가 및 훈련 상황 update, Tokenizer upgrade',
        presenter: '송창길',
        documentPath: '/documents/sm-7-1.md'
      },
      {
        title: 'JOB-KOREA 크롤링',
        presenter: '송병채',
        documentPath: '/documents/sm-7-2.md'
      }
    ]
  },
  {
    id: 8,
    round: 8,
    date: '2024-03-05',
    topics: [
      {
        title: 'Labor Space : Unifying Representation of the Labor Market via LLMs',
        presenter: '김도희',
        documentPath: '/documents/sm-8-1.md'
      },
      {
        title: 'JOB-KOREA 크롤링 작업 경과',
        presenter: '송병채',
        documentPath: '/documents/sm-8-2.md'
      }
    ]
  },
  {
    id: 9,
    round: 9,
    date: '2024-03-13',
    topics: [
      {
        title: 'Data Visualization -Flourish-',
        presenter: '김혜정',
        documentPath: '/documents/sm-9-1.md'
      },
      {
        title: 'JOB-KOREA 크롤링 작업 경과',
        presenter: '송병채',
        documentPath: '/documents/sm-9-2.md'
      }
    ]
  },
  {
    id: 10,
    round: 10,
    date: '2024-03-20',
    topics: [
      {
        title: 'LLM훈련 경과 및 경인사 협동과제 중간보고 준비 논의',
        presenter: '송창길',
        documentPath: '/documents/sm-10-1.md'
      },
      {
        title: 'JOB-KOREA 크롤링 작업 경과',
        presenter: '송병채',
        documentPath: '/documents/sm-10-2.md'
      }
    ]
  },
  {
    id: 11,
    round: 11,
    date: '2024-03-27',
    topics: [
      {
        title: '경인사 협동과제 중간보고 준비 논의',
        presenter: '송창길',
        documentPath: '/documents/sm-11-1.md'
      },
      {
        title: 'JOB-KOREA 크롤링 작업 경과',
        presenter: '송병채',
        documentPath: '/documents/sm-11-2.md'
      }
    ]
  },
  {
    id: 12,
    round: 12,
    date: '2024-04-03',
    topics: [
      {
        title: 'JOB-KOREA 크롤링 작업 경과',
        presenter: '송병채',
        documentPath: '/documents/sm-12-1.md'
      },
      {
        title: 'SOCX 지자체 사업 분류 예시',
        presenter: '김도희',
        documentPath: '/documents/sm-12-2.md'
      }
    ]
  },
  {
    id: 13,
    round: 13,
    date: '2024-04-11',
    topics: [
      {
        title: '인공지능 분류 예시',
        presenter: '송창길',
        documentPath: '/documents/sm-13-1.md'
      }
    ]
  },
  {
    id: 14,
    round: 14,
    date: '2024-04-17',
    topics: [
      {
        title: 'JOBKOREA, OECD 데이터 크롤링',
        presenter: '송병채',
        documentPath: '/documents/sm-14-1.md'
      },
      {
        title: '인공지능모델 훈련 상황 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-14-2.md'
      }
    ]
  },
  {
    id: 15,
    round: 15,
    date: '2024-04-23',
    topics: [
      {
        title: 'JOBKOREA, OECD 데이터 정리',
        presenter: '송병채',
        documentPath: '/documents/sm-15-1.md'
      },
      {
        title: 'Llama-3 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-15-2.md'
      }
    ]
  },
  {
    id: 16,
    round: 16,
    date: '2024-05-08',
    topics: [
      {
        title: 'JOBKOREA 직무 Inference',
        presenter: '송병채',
        documentPath: '/documents/sm-16-1.md'
      }
    ]
  },
  {
    id: 17,
    round: 17,
    date: '2024-05-16',
    topics: [
      {
        title: 'JOBKOREA 크롤링 현황, HTML 데이터 요약 과정 소개',
        presenter: '송병채',
        documentPath: '/documents/sm-17-1.md'
      }
    ]
  },
  {
    id: 18,
    round: 18,
    date: '2024-05-27',
    topics: [
      {
        title: 'HTML 데이터 요약 및 상세요강 JSON 변환 과정 소개',
        presenter: '송병채',
        documentPath: '/documents/sm-18-1.md'
      },
      {
        title: 'K2S3 H100 서버 구축 현황 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-18-2.md'
      }
    ]
  },
  {
    id: 19,
    round: 19,
    date: '2024-06-05',
    topics: [
      {
        title: '인공지능 세미나 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-19-1.md'
      },
      {
        title: '인공지능을 활용한 구인글 구조화(JobInfo Structuring using LLM)',
        presenter: '송병채',
        documentPath: '/documents/sm-19-2.md'
      },
      {
        title: 'Labor Space: A Unifying Representation of the Labor Market via Large Language Models 페이퍼 소개 및 Umap 구현 소개',
        presenter: '김성운, KDI SCHOOL',
        documentPath: '/documents/sm-19-3.md'
      }
    ]
  },
  {
    id: 20,
    round: 20,
    date: '2024-06-13',
    topics: [
      {
        title: '인공지능 세미나 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-20-1.md'
      },
      {
        title: '지역별 첨단분야 인력수요 및 인재역량 분석 착수보고 논의 및 시연',
        presenter: '송병채',
        documentPath: '/documents/sm-20-2.md'
      }
    ]
  },
  {
    id: 21,
    round: 21,
    date: '2024-06-19',
    topics: [
      {
        title: '인공지능 세미나 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-21-1.md'
      },
      {
        title: '크롤링 현황, OCR 적용 현황, LLM Inference Batch Mode 적용 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-21-2.md'
      }
    ]
  },
  {
    id: 22,
    round: 22,
    date: '2024-06-26',
    topics: [
      {
        title: '인공지능 세미나 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-22-1.md'
      },
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-22-2.md'
      }
    ]
  },
  {
    id: 23,
    round: 23,
    date: '2024-07-03',
    topics: [
      {
        title: 'llm leaderboard update 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-23-1.md'
      },
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-23-2.md'
      }
    ]
  },
  {
    id: 24,
    round: 24,
    date: '2024-07-10',
    topics: [
      {
        title: '서버 update 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-24-1.md'
      },
      {
        title: 'Jobkorea 및 서지정보 DB구축 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-24-2.md'
      }
    ]
  },
  {
    id: 25,
    round: 25,
    date: '2024-07-17',
    topics: [
      {
        title: 'google gemma 소개',
        presenter: '송창길',
        documentPath: '/documents/sm-25-1.md'
      },
      {
        title: 'Jobkorea 및 서지정보 작업 현황 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-25-2.md'
      }
    ]
  },
  {
    id: 26,
    round: 26,
    date: '2024-07-24',
    topics: [
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-26-1.md'
      },
      {
        title: 'SOCX 구조 소개',
        presenter: '김도희',
        documentPath: '/documents/sm-26-2.md'
      }
    ]
  },
  {
    id: 27,
    round: 27,
    date: '2024-07-31',
    topics: [
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-27-1.md'
      },
      {
        title: 'SOCX 지출 규모 소개',
        presenter: '김도희',
        documentPath: '/documents/sm-27-2.md'
      }
    ]
  },
  {
    id: 28,
    round: 28,
    date: '2024-08-14',
    topics: [
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-28-1.md'
      },
      {
        title: 'SOCX 지자체 LLM 분석',
        presenter: '김도희',
        documentPath: '/documents/sm-28-2.md'
      }
    ]
  },
  {
    id: 29,
    round: 29,
    date: '2024-08-21',
    topics: [
      {
        title: '크롤링 현황, Jobkorea 직무분석 프로세스 설명',
        presenter: '송병채',
        documentPath: '/documents/sm-29-1.md'
      },
      {
        title: 'SOCX 지자체 LLM 분석',
        presenter: '김도희',
        documentPath: '/documents/sm-29-2.md'
      }
    ]
  },
  {
    id: 30,
    round: 30,
    date: '2024-09-04',
    topics: [
      {
        title: 'umap 스터디',
        presenter: '송창길',
        documentPath: '/documents/sm-30-1.md'
      },
      {
        title: '교육부 과제(수요 및 양성) 진행 현황 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-30-2.md'
      },
      {
        title: 'SOCX 지자체 LLM 분석',
        presenter: '김도희',
        documentPath: '/documents/sm-30-3.md'
      }
    ]
  },
  {
    id: 31,
    round: 31,
    date: '2024-09-11',
    topics: [
      {
        title: 'LLM 현황 공유',
        presenter: '송창길',
        documentPath: '/documents/sm-31-1.md'
      },
      {
        title: '교육부 과제(수요 및 양성) 진행 현황 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-31-2.md'
      }
    ]
  },
  {
    id: 32,
    round: 32,
    date: '2024-09-25',
    topics: [
      {
        title: '채용공고 데이터 시각화 예시',
        presenter: '송창길',
        documentPath: '/documents/sm-32-1.md'
      },
      {
        title: '교육부 과제(수요 및 양성) 진행 현황 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-32-2.md'
      }
    ]
  },
  {
    id: 33,
    round: 33,
    date: '2024-10-02',
    topics: [
      {
        title: 'kedi 중간보고 자료 준비 논의',
        presenter: '송창길',
        documentPath: '/documents/sm-33-1.md'
      },
      {
        title: '교육부 과제(수요 및 양성) 진행 현황 공유',
        presenter: '송병채',
        documentPath: '/documents/sm-33-2.md'
      }
    ]
  },
  {
    id: 34,
    round: 34,
    date: '2024-10-16',
    topics: [
      {
        title: '교육부 과제(수요) 진행 현황',
        presenter: '송창길',
        documentPath: '/documents/sm-34-1.md'
      },
      {
        title: 'SOCX 산출 DB 설정 관련 소개',
        presenter: '김도희',
        documentPath: '/documents/sm-34-2.md'
      }
    ]
  },
  {
    id: 35,
    round: 35,
    date: '2024-10-23',
    topics: [
      {
        title: '과학기술/이공계 채용공고 분석',
        presenter: '사람인 AI LAB',
        documentPath: '/documents/sm-35-1.md'
      },
      {
        title: '교육부 과제(수요) 진행 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-35-2.md'
      },
      {
        title: '지자체 SOCX 산출 경과 공유',
        presenter: '김도희',
        documentPath: '/documents/sm-35-3.md'
      }
    ]
  },
  {
    id: 36,
    round: 36,
    date: '2024-10-30',
    topics: [
      {
        title: '교육부 과제(수요/양성) 진행 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-36-1.md'
      },
      {
        title: '지자체 SOCX 데이터 DB 작업 공유',
        presenter: '김도희',
        documentPath: '/documents/sm-36-2.md'
      }
    ]
  },
  {
    id: 37,
    round: 37,
    date: '2024-11-07',
    topics: [
      {
        title: '교육부 과제(수요/양성) 진행 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-37-1.md'
      },
      {
        title: '채용공고 데이터 시각화 예시',
        presenter: '송창길',
        documentPath: '/documents/sm-37-2.md'
      }
    ]
  },
  {
    id: 38,
    round: 38,
    date: '2024-11-13',
    topics: [
      {
        title: '채용공고 DB 구축 프로세스 설명',
        presenter: '송창길',
        documentPath: '/documents/sm-38-1.md'
      },
      {
        title: '교육부 과제(수요/양성) 진행 현황',
        presenter: '송병채',
        documentPath: '/documents/sm-38-2.md'
      },
      {
        title: 'SOCX 분류 프롬프트',
        presenter: '김도희, 송창길',
        documentPath: '/documents/sm-38-3.md'
      }
    ]
  },
];

export default seminarData;