// src/components/SankeyChart.jsx

import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js';
import createPlotComponent from 'react-plotly.js/factory';

const Plot = createPlotComponent(Plotly);

const SankeyChart = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/sankey_data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching Sankey data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading Sankey Chart...</div>;
  }

  if (!data) {
    return <div>No Sankey data available</div>;
  }

  // 노드 색상 설정 (카테고리별로 색상 지정)
  const colors = {
    'Skills': 'rgba(31, 119, 180, 0.8)',          // 파란색
    'Advanced Fields': 'rgba(255, 127, 14, 0.8)', // 주황색
    'Industries': 'rgba(44, 160, 44, 0.8)',       // 녹색
    'Job Roles': 'rgba(214, 39, 40, 0.8)'         // 빨간색
  };

  // 노드 인덱스에 따라 카테고리 지정
  const nodeCategories = [
    'Skills', 'Skills', 'Skills',                   // 0-2
    'Advanced Fields', 'Advanced Fields', 'Advanced Fields', // 3-5
    'Industries', 'Industries', 'Industries',       // 6-8
    'Job Roles', 'Job Roles', 'Job Roles'           // 9-11
  ];

  // 노드 색상 배열 생성
  const nodeColors = nodeCategories.map(category => colors[category]);

  const plotData = [
    {
      type: 'sankey',
      orientation: 'h',
      valueformat: '.0f',
      valuesuffix: '명',
      node: {
        pad: 15,
        thickness: 20,
        line: {
          color: 'black',
          width: 0.5
        },
        label: data.nodes.map((node) => node.name),
        color: nodeColors,
        hovertemplate: '%{label}<extra></extra>'
      },
      link: {
        source: data.links.map((link) => link.source),
        target: data.links.map((link) => link.target),
        value: data.links.map((link) => link.value),
        hovertemplate:
          '출발: %{source.label}<br>' +
          '도착: %{target.label}<br>' +
          '인원 수: %{value}<extra></extra>'
      }
    }
  ];

  const layout = {
    title: '',
    font: {
      size: 12
    },
    hovermode: 'x',
    margin: { t: 50, l: 50, r: 50, b: 50 },
  };

  return (
    <div>
      <Plot data={plotData} layout={layout} style={{ width: '100%', height: '600px' }} />
    </div>
  );
};

export default SankeyChart;
