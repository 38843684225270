// src/components/Heatmap.jsx

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const Heatmap = () => {
  const [data, setData] = useState(null);
  const [colors, setColors] = useState({
    color1: '#ffffcc',
    color2: '#ffeda0',
    color3: '#fed976',
    color4: '#feb24c',
    color5: '#fd8d3c',
    color6: '#fc4e2a',
    color7: '#e31a1c',
    color8: '#bd0026',
    color9: '#800026'
  });

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/heatmap_data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.error('Error fetching heatmap data:', error);
      });
  }, []);

  const handleColorChange = (colorKey, newColor) => {
    setColors(prevColors => ({
      ...prevColors,
      [colorKey]: newColor
    }));
  };

  if (!data) {
    return <div>Loading Heatmap...</div>;
  }

  const plotData = [
    {
      x: data.x,
      y: data.y,
      z: data.z,
      type: 'heatmap',
      colorscale: [
        [0, colors.color1],
        [0.3, colors.color2],
        [0.4, colors.color3],
        [0.5, colors.color4],
        [0.6, colors.color5],
        [0.7, colors.color6],
        [0.8, colors.color7],
        [0.9, colors.color8],
        [1, colors.color9]
      ],
      zmin: 1,
      zmax: 10,
      hoverongaps: false,
      colorbar: {
        title: '연관성 점수',
        titleside: 'right',
      },
      hovertemplate: '스킬/첨단 분야: %{x}<br>업종/직무: %{y}<br>연관성 점수: %{z}<extra></extra>',
    },
  ];

  const layout = {
    title: '',
    xaxis: {
      title: '스킬 및 첨단 분야',
      tickangle: -45,
      automargin: true,
    },
    yaxis: {
      title: '업종 및 직무',
      automargin: true,
    },
    annotations: [],
    width: 900,
    height: 700,
    margin: { t: 100 },
  };

  // 히트맵의 각 셀에 값 표시
  for (let i = 0; i < data.y.length; i++) {
    for (let j = 0; j < data.x.length; j++) {
      const currentValue = data.z[i][j];
      const textColor = currentValue > 5 ? 'white' : 'black';
      layout.annotations.push({
        x: data.x[j],
        y: data.y[i],
        text: currentValue,
        font: {
          color: textColor,
          size: 12,
        },
        showarrow: false,
      });
    }
  }

  return (
    <div>
      <Plot
        data={plotData}
        layout={layout}
        useResizeHandler={true}
        style={{ width: '100%', height: '100%' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
        {Object.entries(colors).map(([colorKey, colorValue]) => (
          <div key={colorKey}>
            <input
              type="color"
              value={colorValue}
              onChange={(e) => handleColorChange(colorKey, e.target.value)}
            />
            <label>{colorKey}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Heatmap;