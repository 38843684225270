// src/components/UMAPVisualization.jsx

import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotComponent from 'react-plotly.js/factory';

const Plot = createPlotComponent(Plotly);

const UMAPVisualization = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/umap_data.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json) => {
        setData(json);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching UMAP data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  // 카테고리별 색상 매핑
  const categoryColors = {
    'Programming Language': 'blue',
    'AI/ML': 'red',
    'Frontend Framework': 'green',
    'Database': 'purple',
    'DevOps': 'orange',
    'Security': 'brown',
    'Management': 'cyan',
    // 필요한 경우 더 추가
  };

  // 카테고리별 데이터 그룹화
  const groupedData = {};
  data.forEach((item) => {
    if (!groupedData[item.category]) {
      groupedData[item.category] = [];
    }
    groupedData[item.category].push(item);
  });

  // Plotly 데이터 생성
  const plotData = Object.keys(groupedData).map((category) => ({
    x: groupedData[category].map((d) => d.x),
    y: groupedData[category].map((d) => d.y),
    text: groupedData[category].map((d) => d.label),
    customdata: groupedData[category].map((d) => [d.category, d.popularity]),
    name: category,
    type: 'scatter',
    mode: 'markers+text',
    marker: {
      color: categoryColors[category],
      size: groupedData[category].map((d) => (d.popularity / 100) * 20 + 5),
      sizemode: 'area',
      sizeref: 2.0 * Math.max(...data.map((d) => d.popularity)) / (20 ** 2),
      line: { color: 'black', width: 1 }, // 마커 테두리 추가
    },
    textposition: 'top center',
    hovertemplate:
      '<b>%{text}</b><br>' +
      'Category: %{customdata[0]}<br>' +
      'Popularity: %{customdata[1]:.0f}<extra></extra>', // 인기도를 소수점 없이 표시
  }));

  const layout = {
    title: '',
    xaxis: { title: '', zeroline: false },
    yaxis: { title: '', zeroline: false },
    hovermode: 'closest',
    legend: { title: { text: 'Categories' } },
    margin: { t: 50, l: 50, r: 50, b: 50 },
  };

  const config = {
    responsive: true,
    displayModeBar: true,
    scrollZoom: true,
  };

  return (
    <div>
      <Plot data={plotData} layout={layout} config={config} style={{ width: '100%', height: '600px' }} />
    </div>
  );
};

export default UMAPVisualization;
